<template>
  <div class="sells q-px-md">

    <!-- QUADRO RESUMO -->
    <div class="fluxo responsive full-width q-mt-md" style="height: 100%;">
      <div class="box bg-verde">
        <div class="title bLTR text-h6 text-white">
          Resumo do Estoque
        </div>
        <div class="row bg-verde bA" v-for="resumo in resumoStock" :key="resumo" style="height: 100%;">
          <div class="adquirida col-4">
            
            <q-card square class="adquirida">
              <div class="title row bB justify-center bg-verdeclaro">
                <div v-if="isMobile" class="text text-subtitle1 text-bold">
                  Adquirido
                </div>
                <div v-else class="text text-subtitle1 text-bold">
                  Quantidade Adquirida
                </div>
              </div>
              <div class="body column q-py-lg">
                <div class="valor text-verde text-bold">
                    {{ resumo.adq }}
                </div>
              </div>
            </q-card>

          </div>
          <div class="vendida col-4">

            <q-card square class="vendida">
              <div class="title row bB justify-center bg-verdeclaro">
                <div v-if="isMobile" class="text text-subtitle1 text-bold">
                  Vendido
                </div>
                <div v-else class="text text-subtitle1 text-bold">
                  Quantidade Vendida
                </div>
              </div>
              <div class="body column q-py-lg">
                <div class="valor text-verde text-bold">
                    {{ resumo.vdd }}
                </div>
              </div>
            </q-card>

          </div>
          <div class="saldo col-4">

            <q-card square class="saldo">
              <div class="title row bB justify-center bg-verdeclaro">
                <div class="text text-subtitle1 text-bold">
                  Saldo
                </div>
              </div>
              <div class="body column q-py-lg">
                <div class="valor text-verde text-bold">
                    {{ resumo.sld }}
                </div>
              </div>
            </q-card>

          </div>
        </div>
      </div>
    </div>

    <!-- FAIXA DE CONTROLE -->
    <div class="btns q-mt-md row justify-center">
      <q-btn no-caps icon="fas fa-cubes" label="Novo Registro" @click="onAdd" style="width: 280px; background-color: var(--primary)" class="q-mb-md q-mx-md text-white" />
      <div class="filtroStock q-ml-xl">
        <div class="itens q-py-sm q-gutter-x-md" style="border: 1px solid #9696; border-radius: 5px">
          <q-radio dense v-model="controlStock" @click="onFilter(controlStock)" class="text-caption" val="zero" label="Sem Estoque" />
          <q-radio dense v-model="controlStock" @click="onFilter(controlStock)" class="text-caption" val="maior" label="Estoque Disponível" />
          <q-radio dense v-model="controlStock" @click="onFilter(controlStock)" class="text-caption q-mr-md" val="total" label="Exibir Todos" />
        </div>
      </div>
    </div>

    <!-- TABELA DE ACOMPANHAMENTO -->
    <div class="table">
      <q-table
        dense
        class="myTable"
        :rows="rows"
        :columns="cols"
        row-key="name"
        :rows-per-page-options="[10, 20, 50, 100, 99999]"
        rows-per-page-label="Linhas por página: "
        :filter="filter"
        no-results-label="Sem dados a serem mostrados"
        no-data-label="Sem dados a serem mostrados"
        @row-click="onEditRow"
      >
        <template v-slot:top-left>
          <q-input filled dense dark v-model="filter" placeholder="Pesquisar" style="width: 150px">
            <template v-slot:append>
              <q-icon name="search" />
            </template>
          </q-input>
        </template>
        <template v-slot:top-right>
          <q-btn
            flat
            rounded
            style="background-color: var(--verdeclaro); color: white"
            icon="file_download"
            @click="exportTable(rows)"
          />
        </template>
      </q-table>
    </div>

    <!-- MODAL PARA INSERÇÃO DE NOVOS REGISTROS -->
    <q-dialog v-model="modalStock" full-width persistent>
      <div class="bg-white q-pa-lg q-gutter-y-sm">
        <div v-if="editRow" class="title text-h5 text-bold text-center text-verde q-mb-md">
          EDITANDO ESTOQUE
        </div>
        <div v-else class="title text-h5 text-bold text-center text-verde q-mb-md">
          INCLUSÃO DE NOVOS ITEM NO ESTOQUE
        </div>

        <q-separator spaced size="5px" style="background-color: var(--primary)" />
        
        <q-scroll-area style="width: 100%; height: 60vh;" v-show="rebuild">

          <div class="respRowForm q-gutter-y-sm q-mt-sm">
            <q-select v-if="!editRow" class="twoFields" v-model="category" :options="categoria" label="Categoria" outlined @blur="onSize(category)" />
            <q-input v-else class="twoFields" outlined v-model="category" type="text" label="Categoria" />
            <q-input class="twoFields" outlined v-model="product" type="text" label="Produto" />
          </div>

          <div class="respRowForm q-gutter-y-sm q-mt-sm">
            <q-input class="threeFields" outlined v-model="brand" type="text" label="Marca" />
            <q-input class="threeFields" v-model="sizes" label="Armazenamento" outlined />
            <q-input class="threeFields" v-model="color" type="text" label="Cor" outlined />
            <!-- <q-input class="threeFields" outlined v-model="qtd_adq" type="number" label="Quantidade Adquirida" suffix="unidades" @change="onQtd" /> -->
          </div>

          <div v-if="!editRow" class="respRowForm q-gutter-y-sm q-mt-sm justify-right">
            <q-input
              class="threeFields"
              v-model="costUnit"
              type="number"
              label="Custo Unitário"
              outlined
            />
            <q-input
              class="threeFields"
              v-model="qtd_adq"
              type="number"
              label="Quantidade"
              outlined
            />
            <q-input
              class="threeFields"
              v-model="sugestPrice"
              type="number"
              label="Preço Venda Sugerido"
              outlined
            />
          </div>

          <div v-else class="respRowForm justify-center q-gutter-y-sm q-mt-sm">
            <div class="gradeTable borderRadius q-pa-sm">
              <q-scroll-area class="scroll_DetailWarehouse" v-show="editRow">
                <!-- style="width: 60vw; height: 33vh;"> -->

                <q-table
                  dense
                  class="myTable"
                  :rows="combobox"
                  :columns="comboboxCols"
                  row-key="times"
                  :rows-per-page-options="[5]"
                  rows-per-page-label="Linhas por página: "
                  :filter="comboboxFilter"
                  no-results-label="Sem dados a serem mostrados"
                  no-data-label="Sem dados a serem mostrados"
                  @row-click="onOptionsEstok"
                />

              </q-scroll-area>
            </div>
          </div>

        </q-scroll-area>

        <div class="btns row justify-center q-pt-md">
          <q-btn outline no-caps color="negative" label="Cancelar" @click="onCancel" />
          <div class="q-mx-md" />
          <q-btn outline no-caps color="primary" label="Salvar" @click="onSave" v-show="!editRow" />
          <div class="q-mx-md" />
          <q-btn v-show="editRow" no-caps color="info" label="Novo Registro" @click="onCopyToNewProduct" />
        </div>
      </div>
    </q-dialog>

    <!-- MODAL PARA EDIÇÃO DO ESTOQUE -->
    <q-dialog v-model="modalEditStock" full-width persistent>
      <div class="bg-white q-pa-lg q-gutter-y-sm">
        <div class="title text-h5 text-bold text-center text-verde q-mb-md">
          EDITANDO ESTOQUE
        </div>

        <q-separator spaced size="5px" style="background-color: var(--primary)" />
        
        <q-scroll-area style="width: 100%; height: 60vh;" v-show="rebuild">

          <div class="respRowForm q-gutter-y-sm q-mt-sm">
            <!-- <q-select class="twoFields" v-model="stkCategory" :options="categoria" label="Categoria" outlined @blur="onSize(category)" /> -->
            <q-input class="twoFields" outlined v-model="stkCategory" type="text" label="Categoria" disable />
            <q-input class="twoFields" outlined v-model="stkProduct" type="text" label="Produto" disable />
          </div>

          <div class="respRowForm q-gutter-y-sm q-mt-sm">
            <q-input class="threeFields" outlined v-model="stkBrand" type="text" label="Marca" disable />
            <q-input class="threeFields" outlined v-model="stkSizes" type="text" label="Armazenamento" disable />
            <q-input class="threeFields" outlined v-model="stkColors" type="text" label="Cor" disable />
          </div>

          <div class="respRowForm q-gutter-y-sm q-mt-sm">
            <q-input :class="classNewStk" outlined v-model="stkPrice" type="number" label="Preço Sugerido" />
            <q-input :class="classNewStk" outlined v-model="stkCost" type="number" label="Custo" :disable="!stkCopyToNew" />
            <q-input :class="classNewStk" outlined v-model="stkQuant" type="number" label="Qtdade Adquirida" @change="onChangeQtdSTK" />
            <q-input v-show="!stkCopyToNew" :class="classNewStk" outlined v-model="stkSaldo" type="number" label="Saldo" disable />
          </div>

        </q-scroll-area>

        <div class="btns row justify-center q-pt-md">
          <q-btn outline no-caps color="negative" label="Cancelar" @click="onCancelEditWH" />
          <div class="q-mx-md" />
          <q-btn outline no-caps color="primary" label="Salvar" @click="onSaveEditWH" />
        </div>
      </div>
    </q-dialog>

  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { Dialog } from 'quasar';
import { globaisVar } from '../../globais/GlobalVar';
import { defaultColor } from '../../helpers/defaultColor';
import { categorys } from '../../globais/Categorys';
import { supabase } from '../../supabase';

export default {
  data() {
    return {
      enterprise: localStorage.getItem('enterprise'),
      modalStock: false,
      filter: null,
      cols: [
        { name: 'categoria',           align: 'center', label: 'Categoria',               field: 'categoria',          sortable: true },
        { name: 'produtos',          align: 'center', label: 'Produto',               field: 'produtos',         sortable: true },
        { name: 'marca',         align: 'center', label: 'Marca',             field: 'marca',        sortable: true },
        { name: 'cor',           align: 'center', label: 'Cor',               field: 'cor',          sortable: true },
        { name: 'tamanho',        align: 'center', label: 'Armazenamento',            field: 'tamanho',       sortable: true },
        // { name: 'costUnit',        align: 'center', label: 'Custo Unitário',            field: 'costUnit',       sortable: true,
        //   format: val => parseFloat(val).toLocaleString('pt-BR', {minimumFractionDigits: 2})
        // },
        { name: 'qtd_adquirida',  align: 'center', label: 'Quant. Adquirida',   field: 'qtd_adquirida', sortable: true, format: val => parseInt(val).toLocaleString('pt-br') },
        { name: 'qtd_vendida',    align: 'center', label: 'Quant. Vendida',     field: 'qtd_vendida',   sortable: true, format: val => parseInt(val).toLocaleString('pt-br') },
        { name: 'saldo',          align: 'center', label: 'Quant. Disponível',  field: 'saldo',         sortable: true, format: val => parseInt(val).toLocaleString('pt-br') },
      ],
      rows: [],
      optSizes: globaisVar.optSize,
      optColor: globaisVar.optColor,
      optCategory: globaisVar.optCategory,
      resumoStock: null,
      controlStock: 'total',
      categoria: [],
      tipo: [],
      type: null,

      //~> PARA O MODAL
      category: null,
      product: null,
      brand: null,
      color: null,
      sizes: null,
      qtd_adq: 0,
      costUnit: 0,
      sugestPrice: 0,
      recno: null,
      rebuild: true,
      combobox: null,
      comboboxCols: [
        { name: 'liga',    align: 'center',  label: 'Categoria',      field: 'liga',      sortable: true },
        { name: 'times',   align: 'center',  label: 'Produto',        field: 'times',     sortable: true },
        { name: 'modelo',  align: 'center',  label: 'Marca',          field: 'modelo',    sortable: true },
        { name: 'tamanho', align: 'center',  label: 'Armazenamento',  field: 'tamanho',   sortable: true },
        { name: 'sexo',    align: 'center',  label: 'Cor',            field: 'sexo',      sortable: true },
        { name: 'preco',   align: 'center',  label: 'Preço Sugerido', field: 'preco',     sortable: true, format: val => parseFloat(val).toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) },
        { name: 'custo',   align: 'center',  label: 'Custo Unit',     field: 'custo',     sortable: true, format: val => parseFloat(val).toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) },
        { name: 'saldo',   align: 'center',  label: 'Saldo',          field: 'saldo',     sortable: true, format: val => parseInt(val).toLocaleString('pt-br') },
      ],

      //~> CONTROLA GRAVAÇÃO vs EDIÇÃO
      editRow: false,
      modalEditStock: false,
      stkCategory: null,
      stkProduct: null,
      stkBrand: null,
      stkSizes: null,
      stkColors: null,
      stkPrice: null,
      stkCost: null,
      stkQuant: null,
      stkSaldo: null,
      stkVend: null,
      stkRecno: null,

      stkCopyToNew: false,
    }
  },
  computed: {
    isMobile() {
      return (this.$q.screen.sm || this.$q.screen.xs);
    },
    classNewStk() {
      if(this.stkCopyToNew)
      {
        return 'threeFields';
      } else {
        return 'fourFields';
      }
    }
  },
  methods: {
    ...mapActions('Functions', [ 'rpc' ]),
    ...mapActions('Querys', [ 'insertData', 'updateData' ]),
    resetForm() { //~> OK
      //~> Fazendo RESET caso o usuário cancele ou entra para preencher o Form
      this.category = ''
      this.product = ''
      this.brand = ''
      this.color = ''
      this.sizes = ''
      this.qtd_adq = ''
      this.costUnit = ''
      this.sugestPrice = ''
      this.editRow = false
    },
    validFields() { //~> OK
      if(
        this.category == '' || this.category == null ||
        this.product == '' || this.product == null ||
        this.brand == '' || this.brand == null ||
        this.color == '' || this.color == null ||
        this.sizes == '' || this.sizes == null
      ) {
        this.statusFields = false;
        Dialog.create({
          title: 'Gestão Eletrônicos',
          message: 'Favor preencher todos os campos'
        });
      } else {
        this.statusFields = true;
      }
    },
    onAdd() { //~> OK
      this.resetForm();
      this.modalStock = true;
    },
    onChangeQtdSTK() {
      this.stkSaldo = this.stkQuant - this.stkVend
    },
    onCancel() { //~> OK
      Dialog.create({
        title: 'Gestão Eletrônicos',
        message: 'Deseja realmente sair? Ao sair o preenchimento será perdido.',
        persistent: true,
        cancel: true,
      }).onOk(() => {
        this.resetForm();
        this.editRow = false;
        this.modalStock = false;
      })
    },
    onCancelEditWH() {
      this.modalEditStock = false
    },
    async onSaveEditWH() {

      const exist_stock = await this.rpc({
        function: 'exist_stock',
        enterprise: localStorage.getItem('enterprise'),
        league: ( //~> Em LEAGUE estou chamando a KEY_STOCK
          this.stkCategory + this.stkProduct + this.stkBrand + this.stkSizes + this.stkColors + this.stkCost 
        )
      })

      // console.log('existe estoque', exist_stock);

      if(this.stkCopyToNew) { //OK VALIDADO

        if(exist_stock.length > 0) {
            let qtdade = parseFloat(this.stkQuant) + (exist_stock ? parseFloat(exist_stock[0]?.qtd) : 0)
            const atualiza = await this.updateData({
              table: 'GE_WAREHOUSE',
              fields: {
                'GE_QTD_ACQUIRED': qtdade,
                'GE_SUGEST_PRICE': this.stkPrice
              },
              match: {
                'RECNO': exist_stock[0].recno
              }
            })

            if(atualiza) {
              Dialog.create({
                title: 'FATURE ELETRÔNICOS',
                message: 'Produto já cadastrado...<br />Atualizado <b>Preço Sugerido</b> e <b>Quantidades</b>.',
                html: true
              })
            }

        } else {

          const {data} = await supabase.from('GE_WAREHOUSE').insert({
            GE_TEAM: this.stkProduct,
            GE_LEAGUE: this.stkCategory,
            GE_SIZE: this.stkSizes,
            GE_MODEL: this.stkBrand,
            GE_GENDER: this.stkColors,
            GE_QTD_ACQUIRED: this.stkQuant,
            GE_ENTERPRISE: this.enterprise,
            DELETED: ' ',
            GE_UNITCOST: this.stkCost,
            GE_SUGEST_PRICE: this.stkPrice,
          })

          if(data) {
            Dialog.create({
              title: 'FATURE ELETRÔNICOS',
              message: 'Produto gravado com sucesso!'
            })

            this.executeQuery();
            this.modalEditStock = false;
            this.stkCopyToNew = false;
          } else {
            Dialog.create({
              title: 'FATURE ELETRÔNNICOS',
              message: 'Erro ao gerar novo registro!'
            })
          }
        }
      } 
      else {

        const keyEstoque = exist_stock[0] ? exist_stock[0].recno : '-'

        if(keyEstoque === this.stkRecno) {
          //~> Apenas Alterar this.stkRecno (Custo, Preço e Qtdade)
          await this.updateData({
            table: 'GE_WAREHOUSE',
            fields: {
              'GE_QTD_ACQUIRED': parseInt(this.stkQuant),
              'GE_UNITCOST': parseFloat(this.stkCost).toFixed(2),
              'GE_SUGEST_PRICE': parseFloat(this.stkPrice).toFixed(2),
            },
            match: {
              'RECNO': this.stkRecno
            }
          })

          Dialog.create({
            title: 'FATURE ELETRÔNICOS',
            message: 'Produto alterado com sucesso!'
          })

        } else {
          //~> Excluir this.stkRecno Alterar exist_stock[0].recno (Preço e Qtdade)
          let qtdade = 0
          if(exist_stock.length > 0) {
            qtdade = parseInt(this.stkQuant) + parseInt(exist_stock[0].qtd)
          
            //~> EXCLUINDO
            await supabase
              .from('GE_WAREHOUSE')
              .delete()
              .match({
                'RECNO': this.stkRecno
              })

            //~> ALTERANDO
            await this.updateData({
              table: 'GE_WAREHOUSE',
              fields: {
                'GE_QTD_ACQUIRED': qtdade,
                'GE_UNITCOST': parseFloat(this.stkCost).toFixed(2),
                'GE_SUGEST_PRICE': parseFloat(this.stkPrice).toFixed(2),
              },
              match: {
                'RECNO': exist_stock[0].recno
              }
            })

            Dialog.create({
              title: 'FATURE ELETRÔNICOS',
              message: 'Produto alterado com sucesso!'
            })
          } else {

            //~> ALTERANDO
            await this.updateData({
              table: 'GE_WAREHOUSE',
              fields: {
                'GE_QTD_ACQUIRED': parseInt(this.stkQuant),
                'GE_UNITCOST': parseFloat(this.stkCost).toFixed(2),
                'GE_SUGEST_PRICE': parseFloat(this.stkPrice).toFixed(2),
              },
              match: {
                'RECNO': this.stkRecno
              }
            })

            Dialog.create({
              title: 'FATURE ELETRÔNICOS',
              message: 'Produto alterado com sucesso!'
            })
          }

        }
      }

      this.modalEditStock = false
      this.modalStock = false
      this.executeQuery();
    },
    async onSave() { //~> OK

      this.validFields();
        if(!this.statusFields) return '';

      try {
        
        if(this.editRow) { //~> A EDIÇÃO DO ESTOQUE SERA EXECUTADA NA FUNCTION onSaveEditWH()
          await this.updateData({
            table: 'GE_WAREHOUSE',
            fields: {
              'GE_LEAGUE': String(this.category).trimEnd(),
              'GE_TEAM': String(this.product).trimEnd(),
              'GE_MODEL': String(this.brand).trimEnd(),
              'GE_GENDER': String(this.color).trimEnd(),
              'GE_SIZE': this.sizes,
              'GE_QTD_ACQUIRED': this.qtd_adq,
              // 'GE_UNITCOST': parseFloat(this.costUnit),
            },
            match: {
              'RECNO': this.recno
            }
          })

          Dialog.create({
            title: 'Gestão Eletrônicos',
            message: 'Registro editado com sucesso!'
          });
        } else { //~> OK
          let key_stock = await this.rpc({
            function: 'exist_stock',
            enterprise: localStorage.getItem('enterprise'),
            league: ( //~> Em LEAGUE estou chamando a Key_Stock
              this.category + this.product + this.brand + this.sizes + this.color + this.costUnit 
            )
          })

          if(key_stock.length > 0) {

            let qtdade = parseFloat(this.qtd_adq) + parseFloat(key_stock[0].qtd) 

            await this.updateData({
              table: 'GE_WAREHOUSE',
              fields: {
                'GE_QTD_ACQUIRED': qtdade,
              },
              match: {
                'RECNO': key_stock[0].recno
              }
            })

            Dialog.create({
              title: 'Gestão Eletrônicos',
              message: 'Registro editado com sucesso!'
            });
          } else {
            await this.insertData({
              table: 'GE_WAREHOUSE',
              fields: {
                'GE_LEAGUE': this.category,
                'GE_TEAM': String(this.product).trimEnd(),
                'GE_MODEL': String(this.brand).trimEnd(),
                'GE_SIZE': String(this.sizes).trimEnd(),
                'GE_GENDER': String(this.color).trimEnd(),
                'GE_QTD_ACQUIRED': parseInt(this.qtd_adq),
                'GE_UNITCOST': parseFloat(this.costUnit).toFixed(2),
                'GE_SUGEST_PRICE': parseFloat(this.sugestPrice).toFixed(2),
                'GE_ENTERPRISE': this.enterprise,
                'DELETED': ' ',
              }
            })

            Dialog.create({
              title: 'Gestão Eletrônicos',
              message: 'Registro adicionado com sucesso!'
            });
          }

          this.resetForm();
        }

        this.resetForm();
        this.modalStock = false;
        this.editRow = false;
        this.executeQuery();
      } catch (error) {
        Dialog.create({
          title: 'Gestão Eletrônicos',
          message: error.message
        });
      }
    },
    onDelete() { //~> NÃO UTILIZAR
      Dialog.create({
        title: 'Gestão Eletrônicos',
        message: 'Deseja realmente <span class="text-negative text-bold">EXCLUIR</span> o registro?',
        html: true,
        persistent: true,
        cancel: true,
      }).onOk(async () => {
        //NUNCA EXCLUIREMOS UM REGISTRO, APENAS MARCAMOS NO CAMPO DELETED COMO '*'
          await this.updateData({
            table: 'GE_WAREHOUSE',
            fields: {
              'DELETED': '*'
            },
            match: {
              'RECNO': this.recno
            }
          })

        Dialog.create({
          title: 'Gestão Eletrônicos',
          message: 'Registro excluído com sucesso!'
        });

        this.modalStock = false;
        this.editRow = false;
        this.executeQuery();
      })
    },
    onOptionsEstok(_, row) {
      Dialog.create({
        title: 'Fature Eletrônicos',
        message: `
          ${row.times} - 
          Custo: R$${parseFloat(row.custo).toFixed(2)} - 
          Preço: R$${parseFloat(row.preco).toFixed(2)}
        `,
        cancel: {
          label: 'Excluir',
          color: 'red'
        },
        ok: {
          label: 'Editar',
          color: 'green'
        },
      })
      .onCancel(() => {
        Dialog.create({
          title: 'Fature Eletrônicos',
          message: `Deseja realmente excluir o produto ${row.times}`,
          cancel: {
            label: 'Não',
            color: 'green'
          },
          ok: {
            label: 'Sim',
            color: 'red'
          }
        })
        .onOk(async () => {
          const {data} = await supabase
            .from('GE_WAREHOUSE')
            .update({
              DELETED: '*'
            })
            .match({
              RECNO: row.recno
            })

          if(data) {
            Dialog.create({
              title: 'Fature Eletrônicos',
              message: 'Produto excluído com sucesso!'
            });

            this.editRow = false;
            this.modalStock = false;
            this.executeQuery();
          } else {
            Dialog.create({
              title: 'Fature Eletrônicos',
              message: 'Erro ao excluir o produto.<br />Favor tentar novamente.',
              html: true
            })
          }
        })
      })
      .onOk(() => {
        this.stkCategory = row.liga;
        this.stkProduct = row.times;
        this.stkBrand = row.modelo;
        this.stkSizes = row.tamanho;
        this.stkColors = row.sexo;
        this.stkPrice = row.preco;
        this.stkCost = row.custo;
        this.stkQuant = row.qtd_adq;
        this.stkVend = row.qtd_vend;
        this.stkSaldo = row.saldo;
        this.stkRecno = row.recno;

        this.modalEditStock = true;
        this.stkCopyToNew = false;
        this.modalStock = false;
      })
    },
    async executeQuery() { //~> OK
      //~> PARA ALIMENTAR A TABELA
      let stock = await this.rpc({
        function: 'stock_vs_sells',
        enterprise: localStorage.getItem('enterprise')
      });

      this.rows = stock.map((stk) => {
        return {
          recno: stk.recno,
          categoria: stk.liga,
          produtos: stk.times,
          marca: stk.modelo,
          tamanho: stk.tamanho,
          cor: stk.sexo,
          qtd_adquirida: stk.qtd_adquirida,
          qtd_vendida: stk.qtd_vendida,
          saldo: stk.saldo
        }
      });

      //~> PARA RESUMO DO ESTOQUE
      let resumo = await this.rpc({
        function: 'resumo_stok',
        enterprise: localStorage.getItem('enterprise')
      });

      this.resumoStock = resumo.map((item) => {
        return {
          adq: item.adq,
          vdd: item.vdd,
          sld: item.sld,
        }
      });

      //~> PARA ALIMENTAR O COMBOBOX DAS CATEGORIAS
      let categoria = await categorys();

      this.categoria = categoria.map((cat) => {
        return cat.GE_DESCRIPTION
      })

      this.tipo = categoria.map(cat => {
        return {
          categoria: cat.GE_DESCRIPTION,
          tipo: cat.GE_TYPE
        }
      })
    },
    async onFilter(filter) {
      await this.executeQuery()
      if(filter === 'zero') {
        this.rows = this.rows.filter(row => {
          return row.saldo == 0
        })
      } else if (filter === 'maior') {
        this.rows = this.rows.filter(row => {
          return row.saldo > 0
        })
      }
    },
    async onEditRow(_, row) { //~> OK
      this.editRow = true;

      console.log(this.editRow);

      this.category = row.categoria
      this.product = row.produtos
      this.brand = row.marca
      this.color = row.cor
      this.sizes = row.tamanho
      this.qtd_adq = parseInt(row.qtd_adquirida)
      this.costUnit = parseFloat(row.costUnit).toFixed(2)
      this.recno = row.recno

      this.combobox = await this.rpc({
        function: 'estoque_detalhado',
        enterprise: localStorage.getItem('enterprise'),
        league: (this.category + this.product + this.brand + this.sizes + this.color)
      })

      this.modalStock = true;
    },
    onCopyToNewProduct() {
      this.stkCopyToNew = true

      this.stkCategory = this.category
      this.stkProduct = this.product
      this.stkBrand = this.brand
      this.stkSizes = this.sizes
      this.stkColors = this.color

      this.stkQuant = 1
      this.stkCost = 0.00
      this.stkPrice = 0.00
      this.stkSaldo = this.stkQuant

      this.modalEditStock = true
    },
    onQtd() { //~> OK
      if(this.qtd_adq < 0) { this.qtd_adq = 0 }
    },
    exportTable(aTable) {
      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += [
        Object.keys(aTable[0]).join(";"),
        ...aTable.map(item => Object.values(item).join(";"))
        ].join("\n")
        .replace(/(^\[)|(\]$)/gm, "")
        .replaceAll("ç", "c")
        .replaceAll("ã", "a")
        .replaceAll("â", "a")
        .replaceAll("á", "a")
        .replaceAll("à", "a")
        .replaceAll("ê", "e")
        .replaceAll("é", "e")
        .replaceAll("í", "i")
        .replaceAll("õ", "o")
        .replaceAll("ô", "o")
        .replaceAll("ú", "u");

      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", "gestao_Estoque.csv");
      link.click();
    },
  },
  created() {
    defaultColor();
    this.executeQuery();
  }
}
</script>

<style lang="scss">
  @import url('../../globais/GlobalStyles.scss');

  .scroll_DetailWarehouse {
    height: 33vh;
    width: 60vw;
  }

  @media only screen and (max-width: 767px) {
    .scroll_DetailWarehouse {
      height: 33vh;
      width: 100%;
    }
  }
</style>